<template>
    <div v-if="error">
        <NotFound />
    </div>
    <div v-else id="projects-view">
        <b-card-group deck>
            <div class="projects-entry" :style="{'background-color': colors[project.id % 3]}" v-for="project in projects" :key="project.id">
                <b-link :to="{
                    name: project.link,
                    params: {id: project.id}
                    }" class="text-reset text-decoration-none">
                    <div>
                        <b-card
                            overlay
                            :img-src="api + project.thumbnail.url"
                            img-alt="Card Image"
                            text-variant="white"
                            class="rounded-0"
                        >
                            <b-card-title class="project-title">{{project.title}}</b-card-title>
                        </b-card>
                    </div>
                </b-link>
            </div>

            <!-- The block which generates the project placeholders in different "shades of grey" ;) -->
        
            <div class="projects-entry" :style="{'background-color': colors[index % 3]}" v-for="index in placeholder_count" :key="index">
                <div>
                    <b-card
                        overlay
                        img-alt="Card Image"
                        text-variant="white"
                        class="rounded-0"
                        :style="{'background-color': colors[index % 3]}"
                    />                    
                </div>
            </div>

        </b-card-group>

    </div>
</template>

<script>
    import NotFound from '@/components/NotFound'


    export default {
        name: 'Projects',
        components: {
            NotFound
        },
        data () {
            return {
                placeholder_count: 3,
                colors: [
                    "#bcbcbe",
                    "#949496",
                    "#757577"
                ],
            }
        },
        props: {
            projects: Array,
            projects_length: Number,
            error:NotFound,
            api: String 
        }
    }
</script>

<style scoped>
h4{
    font-weight: bold;
}

@media screen and (min-width: 900px) {
    .projects-entry{
        display: block;
        width: 50%;
        height: 50vh;
        overflow: hidden;
    }
    img{
        border-radius: 0px;
        width: 120%;
    }
    .project-title{
        margin-top: 18%;
        font-size: 5.5rem;
        color:#fdfd00;
    }
}

@media screen and (min-width: 700px) and (max-width:899px) {
    .card{
        display: block;
        width:100vw;
        height: 50vh;
        overflow: hidden;
    }
    img{
        border-radius: 0px;
        width: 120%;
    }
    .project-title{
        margin-top: 18%;
        font-size: 3.5rem;
        color:#fdfd00;
    }

    .projects-entry{
        background-color: black;
    }
}

@media screen and (max-width: 699px) {
    .card{
        display: block;
        width:100vw;
        height: 25vh;
        overflow: hidden;
    }
    img{
        border-radius: 0px;
        width: 120%;
    }
    .project-title{
        margin-top: 18%;
        font-size: 2.5rem;
        color:#fdfd00;
    }
}

.card-deck .card{
    border: 0px;
    margin: 0px;
}

.card-deck{
    margin:0;
}

img{
    margin-left: 0;
    margin-right: auto;
}
</style>