<template>
    <div>
        <Projects :error="error" :projects="projects" :api="api" :projects_length="projects.length" />
    </div>
</template>

<script>
    import Projects from '../components/Projects'

    export default {
        name: 'ProjectList',
        components: {
            Projects
        },
        data () {
            return {
                projects: [],
                projects_length: 0,
                error: null,
                api: process.env.VUE_APP_ALTERED_ROOT_API,
                headers: {'Content-Type': 'application/json'}
            }
        },
        methods: {
            parseJSON: function (resp) {
            return (resp.json ? resp.json() : resp);
            },
            checkStatus: function (resp) {
            if (resp.status >= 200 && resp.status < 300) {
                return resp;
            }
            return this.parseJSON(resp).then((resp) => {
                throw resp;
            });
            }
        },
        async mounted () {
            try {
            const response = await fetch(this.api + "/projects?_sort=id:ASC", {
                method: 'GET',
                headers: this.headers,
            }).then(this.checkStatus)
                .then(this.parseJSON);
            this.projects = response
            } catch (error) {
              this.error = error
            }
        }
    }


</script>

<style scoped>

</style>